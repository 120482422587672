<template>
  <div class="media-preview">
    <button class="btn-close" @click="closePreview"></button>
    <div class="media-container">
      <div class="placeholder" v-if="isLoading">
        <div class="spinner-border text-primary me-3"></div>
        <span class="text-muted">Loading media...</span>
      </div>
      <div
        id="osd"
        :class="{
          invisible:
            isLoading ||
            mimeType == 'application/pdf' ||
            (mimeType || '').includes('video') ||
            mimeType == 'youtube',
        }"
      ></div>
      <div id="pdf" v-if="mimeType == 'application/pdf'">
        <iframe :src="url" class="w-100 h-100"></iframe>
      </div>
      <video
        id="video"
        :src="url"
        class="w-100 h-100"
        controls
        v-if="mimeType && mimeType.includes('video')"
      />
      <div id="youtubeEmbed" v-if="mimeType && mimeType == 'youtube'">
        <iframe :src="url" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import OSD from "openseadragon";

export default {
  name: "media-preview",
  props: ["url", "mimeType"],
  data() {
    return {
      osd: null,
      isLoading: false,
    };
  },
  methods: {
    closePreview() {
      if (this.osd) {
        this.osd.removeAllHandlers("open");
      }

      this.$emit("close");
    },
    loadOSD() {
      this.isLoading = true;

      if (this.osd) {
        this.osd.removeAllHandlers("open");
      }

      this.osd = OSD({
        id: "osd",
        prefixUrl: "/osd/",
        crossOriginPolicy: "Anonymous",
        maxZoomPixelRatio: 10,
        showNavigationControl: false,
        zoomPerClick: 1.0,
        constrainDuringPan: true,
        visibilityRatio: 1,
        animationTime: 0.3,
      }).open({
        type: "image",
        url: `${this.url}`,
      });

      this.osd.addHandler("open", () => {
        this.isLoading = false;
      });
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    if (
      !this.mimeType ||
      (this.mimeType != "application/pdf" &&
        !this.mimeType.includes("video") &&
        !this.mimeType == "youtube")
    ) {
      this.loadOSD();
    }
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>